import React, { useState } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const News = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1)

  let articles = data.allDatoCmsArticle.edges

  if (process.env.GATSBY_VARIANT === "Europe") {
    articles = articles.filter(article => article.node.variant == "europe")
  }

  const itemsPerPage = 3
  const numberOfPages = Math.ceil(articles.length / itemsPerPage)
  const [readMore, setReadMore] = useState(undefined)

  return (
    <React.Fragment>
      <PageTitle title={"News"} />
      <Layout>
        <div className="gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/en")}>Home</a>
              </li>
              <li>
                <a>News</a>
              </li>
            </ul>
          </div>
          <div className="asked-question">
            <h1>News</h1>

            <div className="asked-question__block">
              {articles
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((article, index) => {
                  article = article.node
                  return (
                    <div className="blog__post" key={`blog-post-${index}`}>
                      <img
                        className="blogimg"
                        src={article.image.url}
                        alt={article.image.alt}
                        title={article.image.alt}
                      ></img>
                      <div className="blog__post--wrapper">
                        <p className="blog__post--date">
                          {article.publishDate}
                        </p>
                        <div className="blog__h1">{article.title}</div>
                        <div className="blog-container">
                          <div
                            className="blog__post--body--content"
                            dangerouslySetInnerHTML={{
                              __html:
                                article.description.substring(
                                  0,
                                  readMore === index ? 5000 : 380
                                ) + (readMore === index ? "" : "..."),
                            }}
                          />
                          {article.description.length > 380 &&
                            readMore !== index && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 24,
                                  cursor: "pointer",
                                }}
                                onClick={() => setReadMore(index)}
                              >
                                <p
                                  style={{
                                    marginLeft: 10,
                                    fontWeight: 700,
                                    fontSize: 12,
                                    lineHeight: "15px",
                                    color: "#0B67F0",
                                  }}
                                >
                                  SHOW MORE
                                </p>
                                <svg
                                  width="53"
                                  height="27"
                                  viewBox="0 0 53 27"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginLeft: 10,
                                  }}
                                >
                                  <path
                                    d="M0 13.613H51.1489M51.1489 13.613L38.4471 1.16016M51.1489 13.613L38.4471 26.0658"
                                    stroke="#0B67F0"
                                    strokeWidth="2"
                                    strokeMiterlimit="10"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

        <div className="paginator">
          <svg
            width="53"
            height="27"
            viewBox="0 0 53 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              marginLeft: 75,
              cursor: currentPage === 1 ? "auto" : "pointer",
            }}
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          >
            <path
              d="M52.1489 13.4534L1.00001 13.4534M1.00001 13.4534L13.7019 25.9062M1.00001 13.4534L13.7019 1.00059"
              stroke={currentPage === 1 ? "#B7C0CC" : "#0B67F0"}
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            />
          </svg>

          {Array.from({ length: numberOfPages }, (_, index) => (
            <div
              key={index}
              className={`paginator__item ${
                index + 1 === currentPage ? "paginator__item--active" : ""
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </div>
          ))}

          <svg
            width="53"
            height="27"
            viewBox="0 0 53 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              marginRight: 65,
              cursor: currentPage === numberOfPages ? "auto" : "pointer",
            }}
            onClick={() =>
              currentPage !== numberOfPages && setCurrentPage(currentPage + 1)
            }
          >
            <path
              d="M0.148926 13.4528H51.2978M51.2978 13.4528L38.596 1M51.2978 13.4528L38.596 25.9057"
              stroke={currentPage === numberOfPages ? "#B7C0CC" : "#0B67F0"}
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Layout>

      {/* Bottom-right Scroll-UP button */}
      {/* <a
        className="main-button__top js-scroll-top"
        style={{ opacity: "1" }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span></span>
        <svg
          preserveAspectRatio="none"
          className="progress"
          viewBox="0 0 110 110"
        >
          <circle className="circle-bg" r="50" cx="55" cy="55"></circle>
          <circle
            className="circle-go"
            r="50"
            cx="55"
            cy="55"
            style={{
              strokeDasharray: "314.159px",
              strokeDashoffset: "119.68px",
            }}
          ></circle>
        </svg>
      </a> */}
    </React.Fragment>
  )
}

export default News

export const query = graphql`
  query ($locale: String!) {
    allDatoCmsArticle(
      filter: { locale: { eq: $locale } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          title
          description
          variant
          meta {
            createdAt(formatString: "DD.MM.YYYY")
          }
          publishDate(formatString: "DD.MM.YYYY")
          image {
            url
            alt
          }
        }
      }
    }
  }
`
